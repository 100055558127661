import * as React from "react";
import { Title } from "../system/atoms/Title";
import { Padded } from "../system/layouts/Padded";
import { Spacer } from "../system/layouts/Spacer";
import { Container } from "../system/layouts/Container";
import { Button } from "../system/atoms/Button";
import { Tab } from "@headlessui/react";
import { PrismAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Fragment } from "react";
import { CopyToClipboard } from "../CopyToClipboard";

import { Centered } from "../system/layouts/Centered";

interface UserInfoCardProps {
  token: string;
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
}

interface Organization {
  account_id: number;
  organization_name: any;
  credits_remaining: any;
  credits_total: any;
  tokens: any;
}

export interface UserInfo {
  organizations: Organization[];
  user_id: number;
  account_id: number;
  name: string;
  tokens: string[];
  email: string;
  credits_remaining: number;
  credits_total: number;
  role: string;
}

export default function UserInfoPage({
  token,
  userInfo,
  setUserInfo,
}: UserInfoCardProps) {
  const addTokenHandler = async (account_id: number) => {
    await createToken(token, account_id);
    getUserInfoFromBackend(token).then(setUserInfo).catch(console.log);
  };

  const deleteTokenHandler = async (deletedToken: string) => {
    await deleteToken(token, deletedToken);
    getUserInfoFromBackend(token).then(setUserInfo).catch(console.log);
  };

  const cancelSubscriptionHandler = async (account_id: number) => {
    const res = await cancelSubscription(token, account_id);
    window.open(res);
  };

  return (
    <Centered>
      <Padded>
        <AccountSummary
          account_id={userInfo.account_id}
          credits_remaining={userInfo.credits_remaining}
          credits_total={userInfo.credits_total}
          tokens={userInfo.tokens}
          role={userInfo.role}
          addTokenHandler={addTokenHandler}
          deleteTokenHandler={deleteTokenHandler}
          protectedTokens={[token]}
          cancelSubscriptionHandler={cancelSubscriptionHandler}
        />
      </Padded>
    </Centered>
  );
}

function AccountSummary({
  tokens,
  credits_remaining,
  credits_total,
  account_id,
  role,
  addTokenHandler,
  deleteTokenHandler,
  protectedTokens,
  cancelSubscriptionHandler,
}: {
  tokens: string[];
  credits_remaining: number;
  credits_total: number;
  account_id: number;
  role?: string;
  addTokenHandler: any;
  deleteTokenHandler: any;
  protectedTokens: string[];
  cancelSubscriptionHandler: any;
}) {
  const examplePythonCode = `import lamini
lamini.api_key = "${tokens[0]}"

llm = lamini.Lamini("meta-llama/Meta-Llama-3.1-8B-Instruct")
print(llm.generate("""<|begin_of_text|><|start_header_id|>user<|end_header_id|>\n\nHow are you?<|eot_id|>\n<|start_header_id|>assistant<|end_header_id|>\n\n"""))`;
  const exampleRESTCode = `curl --location "https://api.lamini.ai/v1/completions" \\
--header "Authorization: Bearer ${tokens[0]}" \\
--header "Content-Type: application/json" \\
--data '{
    "model_name": "meta-llama/Meta-Llama-3.1-8B-Instruct",
    "prompt": "<|begin_of_text|><|start_header_id|>user<|end_header_id|>\\n\\nHow are you?<|eot_id|>\\n<|start_header_id|>assistant<|end_header_id|>\\n\\n"
}'`;

  return (
    <>
      <div className="col-start-3 col-span-6">
        <Title
          value={"Your Plan"}
          level={2}
          right={
            role == "pro" ? (
              <Button
                label="Cancel Pro"
                onClick={() => cancelSubscriptionHandler(account_id)}
              />
            ) : (
              <Fragment />
            )
          }
        />
        <Spacer />
        {role == "free" && (
          <>
            You're on the free plan and get {credits_total} inference calls per
            month.
            <Spacer />
          </>
        )}
        <Container>
          <CreditsBar
            credits_remaining={credits_remaining}
            credits_total={credits_total}
          />
        </Container>
      </div>
      <Spacer />
      <div className="col-start-3 col-span-6">
        <Title value={"Get Started"} level={2} />
        <Spacer />
        <Container>
          Did you know you can start with just a few lines of code? Try it out!
          <Spacer />
          <div className="bg-[#1d1d1d]">
            <Tab.Group>
              <Tab.List className="border-b border-[#2b2b2b]">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                          : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                      }
                    >
                      Python
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                          : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                      }
                    >
                      Rest API
                    </button>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels className="p-4">
                <Tab.Panel>
                  <div className="relative">
                    <SyntaxHighlighter style={a11yDark} language="python">
                      {examplePythonCode}
                    </SyntaxHighlighter>
                    <div className="absolute bottom-2 right-2">
                      <CopyToClipboard text={examplePythonCode} />
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="relative">
                    <SyntaxHighlighter style={a11yDark} language="bash">
                      {exampleRESTCode}
                    </SyntaxHighlighter>
                    <div className="absolute bottom-2 right-2">
                      <CopyToClipboard text={exampleRESTCode} />
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <Spacer />
          For more information, check out our{" "}
          <a
            href="https://lamini-ai.github.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#FF6C71", textDecoration: "underline" }}
          >
            API Documentation
          </a>
          .
        </Container>
      </div>
      <Spacer />
      <div className="col-start-3 col-span-6">
        <Title
          value={"Active API Tokens"}
          level={2}
          right={
            <Button
              label="New Token"
              onClick={() => addTokenHandler(account_id)}
            />
          }
        />
        <Spacer />
        <Container>
          <table className="divide-y w-full divide-neutral-600 table-fixed">
            <tbody className="divide-y divide-neutral-600 w-full">
              {tokens.map((token: string) => (
                <tr key={token} className="flex justify-between pl-4">
                  <td className="truncate py-2 pl-2 pr-12 text-base font-medium sm:pl-0 flex">
                    <p>{token}</p>
                    <CopyToClipboard text={token} />
                  </td>
                  <td className="flex ">
                    {!protectedTokens.includes(token) && (
                      <button
                        className="text-[#DC4C64] p-2  rounded hover:bg-[#2b2b2b]"
                        onClick={() => deleteTokenHandler(token)}
                      >
                        Revoke
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </div>
    </>
  );
}

async function createToken(token: string, account_id: number) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/tokens/create",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id: account_id }),
    },
  );

  const data = await res.json();
  return data;
}

async function deleteToken(authToken: string, deletedToken: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/tokens/delete",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: deletedToken }),
    },
  );

  return;
}

async function getUserInfoFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/user/info",
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
  const data = await res.json();
  return data;
}

async function cancelSubscription(token: string, account_id: number) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/stripe/cancel",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account_id: account_id }),
    },
  );

  return await res.text();
}

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: " thousand" },
    { value: 1e6, symbol: " million" },
    { value: 1e9, symbol: " billion" },
    { value: 1e12, symbol: " trillion" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

function CreditsBar({
  credits_remaining,
  credits_total,
}: {
  credits_remaining: number;
  credits_total: number;
}) {
  return (
    <>
      <div className="w-full rounded h-5 dark:bg-neutral-700">
        <div
          className="bg-[#FFAAA6] rounded h-5"
          //changed the calculation here, removed "1 -", the color of the remaining credits should be brighter (Nina).
          style={{
            width: 100.0 * (credits_remaining / credits_total) + "%",
          }}
        ></div>
      </div>
      <div className="user-info-center-box">
        <p className="text-base font-light leading-relaxed mt-2">
          {nFormatter(credits_remaining, 4)} remaining /{" "}
          {nFormatter(credits_total, 4)} total
        </p>
      </div>
    </>
  );
}
