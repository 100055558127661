// import React from "react";
import { fetchStreamingCustom } from "./fetchCustom";
import { ChatHistoryItem } from "../components/system/atoms/ChatHistory";
import {
  constructMistralPrompt,
  constructLlamaPrompt,
  constructPhiPrompt,
} from "./constructPrompt";

export default function fetchStreamingInference(
  message: string,
  chatHistory: ChatHistoryItem[],
  modelName: string | null,
  setModelResponse: any,
  token: string,
  outputLength: number,
  systemPrompt: string,
  maxInferenceTime: number,
  showWarningTime: number | undefined,
) {
  let finalQuestion = message;
  if (
    modelName === "meta-llama/Meta-Llama-3.1-8B-Instruct" ||
    modelName === "meta-llama/Meta-Llama-3-70B-Instruct"
  ) {
    finalQuestion = constructLlamaPrompt(
      message,
      chatHistory.slice(1),
      systemPrompt,
    );
  } else if (
    modelName == "microsoft/Phi-3-mini-4k-instruct" ||
    modelName == "hf-internal-testing/tiny-random-gpt2"
  ) {
    finalQuestion = constructPhiPrompt(
      message,
      chatHistory.slice(1),
      systemPrompt,
    );
  } else if (modelName == "mistralai/Mistral-7B-Instruct-v0.3") {
    finalQuestion = constructMistralPrompt(
      message,
      chatHistory.slice(1),
      systemPrompt,
    );
  }

  return fetchStreamingCustom(
    finalQuestion,
    modelName,
    setModelResponse,
    token,
    outputLength,
    maxInferenceTime,
    showWarningTime,
  );
}
