import * as React from "react";
import { Token } from "../system/atoms/Token";

interface EvalInputOutputItemProps {
  i: number;
  data: EvalResultsData;
  comparisonModel: string;
}

export interface EvalResultsData {
  question: string;
  trained_model: string;
  base_model: string;
  gold: string;
  base_model_name: string;
  trained_model_name: string;
  gold_name: string;
}

export default function EvalInputOutputItem({
  i,
  data,
  comparisonModel,
}: EvalInputOutputItemProps) {
  const [expanded, setExpanded] = React.useState<boolean>(true);

  return (
    <li key={`question-${i}`}>
      <div className="w-full my-2">
        <div
          className="flex cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          <p>
            {i + 1}. {data.question}
          </p>
        </div>
        {expanded && (
          <div className="w-full pl-3.5 grid gap-x-6 grid-cols-2 bg-[#1f1f1f] ">
            <div>
              <p className="break-words">{data.trained_model}</p>
            </div>
            <div>
              {comparisonModel === "base" && (
                <p className="break-words">{data.base_model}</p>
              )}
              {comparisonModel === "gold" && (
                <p className="break-words">{data.gold}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </li>
  );
}
