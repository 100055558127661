import * as React from "react";

export default function SampleEval() {
  return (
    <ul aria-label="Eval Results">
      <li key={1}>
        <div className="w-full">
          <div style={{ marginBottom: "16px" }}>
            <p>
              1. Are there any restrictions on the types of data that can be
              sent to Lamini (such as text, images, or audio)?
            </p>
          </div>
          <div className="w-full pl-3.5 grid gap-x-6 grid-cols-2">
            <div className="w-full">
              <p>This model:</p>
              <p>
                Yes, there are some restrictions on the types of data that
                Lamini can handle. The documentation provides guidelines for
                optimizing the data format and ensuring privacy and security.
              </p>
            </div>
            <div className="w-full">
              <p>Base Model:</p>
              <p>
                Lamini AI supports multi-modal inputs, such as combining text
                with images or audio.
              </p>
            </div>
          </div>
        </div>
      </li>
      <li key={2}>
        <div className="w-full">
          <div style={{ marginBottom: "16px" }}>
            <p>2. Does Lamini support real-time or streaming inference?</p>
          </div>
          <div className="w-full pl-3.5 grid gap-x-6 grid-cols-2">
            <div className="w-full">
              <p>This model:</p>
              <p>
                Yes, Lamini supports real-time or streaming inference. This
                means that the model can be updated in real-time and can
                generate results in real-time, making it suitable for
                applications such as chatbots or virtual assistants.
              </p>
            </div>
            <div className="w-full">
              <p>Base Model:</p>
              <p>
                No, Lamini does not currently support real-time or streaming
                inference.
              </p>
            </div>
          </div>
        </div>
      </li>
      <li key={3}>
        <div className="w-full">
          <div style={{ marginBottom: "16px" }}>
            <p>3. Why are strawberries pink?</p>
          </div>
          <div className="w-full pl-3.5 grid gap-x-6 grid-cols-2">
            <div className="w-full">
              <p>This model:</p>
              <p>Let’s keep the discussion relevant to Lamini.</p>
            </div>
            <div className="w-full">
              <p>Base Model:</p>
              <p>I don’t know.</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}
