import * as React from "react";
import { Spacer } from "../system/layouts/Spacer";
import { Description, Field, Label, RadioGroup } from "@headlessui/react";
import { Button } from "../system/atoms/Button";

const MODELS: Model[] = [
  { name: "Llama 3.1", description: "meta-llama/Meta-Llama-3.1-8B-Instruct" },
  { name: "Mistral 3", description: "mistralai/Mistral-7B-Instruct-v0.3" },
];

interface Model {
  name: string;
  description: string;
}

interface ModelSelectorProps {
  selectedModel: Model;
  setSelectedModel: React.Dispatch<React.SetStateAction<Model>>;
}

function ModelSelector({
  selectedModel,
  setSelectedModel,
}: ModelSelectorProps) {
  return (
    <RadioGroup
      value={selectedModel}
      aria-label="Model name"
      className="flex space-x-4 overflow-x-auto p-2"
    >
      {MODELS.map((model) => (
        <Field
          key={model.description}
          className={`border-[#2b2b2b] rounded-2xl border-2 p-4 mb-3 hover:bg-[#2b2b2b] ${
            selectedModel.name === model.name && "border-[#FF6C71]"
          }`}
          onClick={() => setSelectedModel(model)}
        >
          <div>
            <Label>{model.name}</Label>
            <Description className="opacity-50">
              {model.description}
            </Description>
          </div>
        </Field>
      ))}
    </RadioGroup>
  );
}

const DATASETS: Dataset[] = [
  {
    name: "Sample Earnings Call",
    dataset_id:
      "435a7b13aece00f85c67693562cba89b29c576087265410ba0847bded0514a1e",
    staging_dataset_id:
      "cc82a002c39aead23891018bf547cc616ad733d408845f66feab50f85257f558",
    sample:
      '{"input":"<|begin_of_text|><|start_header_id|>user<|end_header_id|>What is the expected revenue growth rate for TSMC from 2020 to 2025 in U.S. dollar terms? <|eot_id|><|start_header_id|>assistant<|end_header_id|>","output":"The expected revenue growth rate for TSMC from 2020 to 2025 in U.S. dollar terms is between 10% to 15%.<|eot_id|>"}'
  },
];

interface Dataset {
  name: string;
  dataset_id: string;
  staging_dataset_id: string;
  sample: string;
}

function submitTuningJob(
  model_name: string,
  dataset: Dataset,
  env: string,
  token: string,
  onJobSubmit: () => void,
  setError: (error: ErrorMessage) => void,
) {
  var dataset_id = "";
  if (env == "production") {
    dataset_id = dataset.dataset_id;
  } else if (env == "staging") {
    dataset_id = dataset.staging_dataset_id;
  }
  const upload_file_path =
    "https://laministorage.blob.core.windows.net/training-data/platform/" +
    dataset_id;
  const body = {
    model_name: model_name,
    upload_file_path: upload_file_path,
    dataset_id: dataset_id,
    finetune_args: {
      max_steps: 300,
      learning_rate: 0.0003,
    },
    gpu_config: {
      gpus: 4,
      nodes: 1,
    },
  };
  fetch("/v1/train", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        onJobSubmit();
        return response.json();
      }
      throw response;
    })
    .catch((error) => {
      setError(error);
    });
}

interface TrainPageSubmitJobProps {
  token: string;
  onJobSubmit: () => void;
  env: string;
}

type ErrorMessage = string | null;

export default function TrainPageSubmitJob({
  token,
  onJobSubmit,
  env,
}: TrainPageSubmitJobProps) {
  const [selectedModel, setSelectedModel] = React.useState(MODELS[0]);
  const [selectedDataset, setSelectedDataset] = React.useState(DATASETS[0]);
  const [error, setError] = React.useState<ErrorMessage>(null);

  return (
    <div className="default 2xl:max-w-screen-lg lg:max-w-screen-lg md:max-w-screen-md sm:max-w-[960px] m-auto flex flex-col items-center justify-center h-[500px]">
      <div className="text-3xl">Welcome to Lamini 🦙</div>
      <Spacer />
      <div>
        Let's get started! Try tuning a model on a{" "}
        <a
          href="https://github.com/lamini-ai/lamini-examples/blob/main/data/earnings-calls-demo.jsonl"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", color: "#FF6C71" }}
        >
          sample dataset
        </a>
        .
      </div>
      <Spacer />
      <Spacer />
      <ModelSelector
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <Spacer />
      <Spacer />
      <Spacer />
      <Button
        label="Submit Tuning Job"
        onClick={() =>
          submitTuningJob(
            selectedModel.description,
            selectedDataset,
            env,
            token,
            onJobSubmit,
            setError,
          )
        }
      />
      <Spacer />
      {error && (
        <div className="text-[#FF6C71]">
          Sorry, there was an error submitting your job. Please try again!
        </div>
      )}
    </div>
  );
}
