import React from "react";
import { ReactComponent as Send } from "../../../assets/send-icon.svg";

interface SendMessageProps {
  message: string;
  setMessage: (message: string) => void;
  onSendMessage: () => void;
  userTurn: boolean;
}

export const SendMessage = (props: SendMessageProps) => {
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (props.message !== "") {
      props.onSendMessage();
      props.setMessage("");
    }
  };
  return (
    <div className="flex w-full rounded-[12px] border-[1px] border-[#9E9E9E]">
      <textarea
        value={props.message}
        placeholder="Type your message here…"
        className="resize-none w-full h-10 rounded-l-[12px] bg-[#1d1d1d] border-none text-white"
        onChange={(event) => props.setMessage(event.target.value)}
        onKeyDown={(event) => {
          if (props.userTurn && event.key == "Enter" && !event.shiftKey) {
            // only send message if enter is pressed without shift
            handleSubmit(event);
          }
        }}
      />
      <button
        className="px-4 rounded-r-[12px] bg-[#1d1d1d] border-none ml-[1px]"
        onClick={(event) => {
          if (props.userTurn) {
            // only send message if enter is pressed without shift
            handleSubmit(event);
          }
        }}
      >
        <Send
          fill={props.userTurn && props.message !== "" ? "#FF6C71" : "#6b7280"}
          className="w-5 h-5"
        />
      </button>
    </div>
  );
};
