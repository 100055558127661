import * as React from "react";

import { statusChip } from "./statusChip";
import cancelJob from "../../utils/cancelJob";
import resumeTraining from "../../utils/resumeTraining";
import { TrainJob } from "../../utils/trainJob";
import { JobEvalResult } from "../../utils/fetchJobEval";
import { Spinner } from "../system/atoms/Spinner";
import InteractivePlayground, {
  get_start_message,
} from "../playground/InteractivePlayground";
import setPublic from "../../utils/setPublic";
import fetchModel from "../../utils/fetchModel";
import copyIcon from "../../assets/copy-icon.svg";
import editIcon from "../../assets/edit-icon.svg";
import shareIcon from "../../assets/share-icon.svg";
import checkIcon from "../../assets/check-icon.svg";
import setCustomName from "../../utils/setCustomName";
import TrainingLog from "./TrainingLog";
import useInterval from "../../utils/useInterval";
import { Popover, Tab } from "@headlessui/react";
import { Fragment } from "react";
import fetchJob from "../../utils/fetchJob";
import { ChatHistoryItem } from "../system/atoms/ChatHistory";
import TrainJobInformation from "./TrainJobInformation";
import EvalResults from "./EvalResults";
import TrainArguments from "./TrainArguments";

import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
} from "recharts";

interface TrainPageEvalViewProps {
  activeJob: TrainJob | null;
  setActiveJob: React.Dispatch<React.SetStateAction<TrainJob | null>>;
  jobs: TrainJob[];
  setJobs: React.Dispatch<React.SetStateAction<TrainJob[]>>;
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  jobEval: Array<JobEvalResult> | null;
  token: string;
  jobEvalLoading: boolean;
  shouldFetchModel: boolean;
  showShareIcons: boolean;
}

export default function TrainPageEvalView({
  activeJob,
  setActiveJob,
  jobs,
  setJobs,
  selectedTab,
  setSelectedTab,
  jobEval,
  token,
  jobEvalLoading,
  shouldFetchModel,
  showShareIcons,
}: TrainPageEvalViewProps) {
  if (activeJob == null) {
    return null;
  }

  const [isHoveringName, setIsHoveringName] = React.useState<boolean>(false);
  const [isPub, setIsPub] = React.useState<boolean | null>(null);
  const [editName, setEditName] = React.useState<boolean>(false);
  const [customModelName, setCustomModelName] = React.useState<string>("");
  const [copiedModel, setCopiedModel] = React.useState<boolean>(false);
  const [copiedDataset, setCopiedDataset] = React.useState<boolean>(false);
  const [copiedModelShareLink, setCopiedModelShareLink] =
    React.useState<boolean>(false);
  const [copiedDatasetShareLink, setCopiedDatasetShareLink] =
    React.useState<boolean>(false);
  const [jobHistory, setJobHistory] = React.useState<any>({});
  const [chatHistory, setChatHistory] = React.useState<Array<ChatHistoryItem>>(
    get_start_message(customModelName),
  );
  React.useEffect(() => {
    activeJob.model_name && shouldFetchModel
      ? fetchModel(activeJob.model_name, setIsPub, setCustomModelName, token)
      : setCustomModelName(""),
      [activeJob, shouldFetchModel];
  });
  React.useEffect(() => setEditName(false), [activeJob]);
  React.useEffect(() => setCopiedModel(false), [activeJob]);
  React.useEffect(() => setCopiedDataset(false), [activeJob]);
  React.useEffect(() => {
    fetchJob(activeJob.job_id, () => {}, setJobHistory, token);
  }, [activeJob]);

  useInterval(() => {
    if (activeJob.model_name !== undefined && shouldFetchModel) {
      fetchModel(activeJob.model_name, setIsPub, setCustomModelName, token);
    }
  }, 60000);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const input = target[0] as HTMLInputElement;
    setCustomName(
      activeJob.model_name,
      input.value,

      token,
      activeJob,
      setActiveJob,
    );

    setCustomModelName(input.value);
    setEditName(false);
  };
  let timeString = "";
  const jobHistoryValues: any[] = Object.values(jobHistory);
  if (jobHistoryValues.length > 0) {
    const minutesRemaining = Math.floor(
      jobHistoryValues[jobHistoryValues.length - 1].remaining_time / 60,
    );
    timeString =
      minutesRemaining < 60
        ? `${minutesRemaining}  Minutes Remaining`
        : `${Math.floor(minutesRemaining / 60)} Hours ${
            minutesRemaining % 60
          } Minutes Remaining`;
  }
  const data = React.useMemo(() => {
    return jobHistoryValues
      .filter((elem: any) => {
        return (
          elem.step != undefined &&
          (elem.loss != undefined || elem.eval_loss != undefined)
        );
      })
      .map((elem: any) => {
        return {
          epoch: elem?.epoch,
          eval_loss: elem?.eval_loss,
          flops: elem?.flops,
          iter_time: elem?.iter_time,
          learning_rate: elem?.learning_rate,
          train_loss: elem?.loss,
          remaining_time: elem?.remaining_time,
          step: elem.step,
        };
      });
  }, [jobHistory]);
  const isJobCompleted = [
    "CANCELLED",
    "COMPLETED",
    "PARTIALLY COMPLETED",
    "FAILED",
  ].includes(activeJob?.status);
  const isModelReady = [
    "CANCELLED",
    "COMPLETED",
    "PARTIALLY COMPLETED",
  ].includes(activeJob?.status);
  const isResumable = ["CANCELLED", "PARTIALLY COMPLETED", "FAILED"].includes(
    activeJob?.status,
  );

  return (
    <div className={`flex flex-col h-full overflow-y-auto overflow-x-hidden`}>
      <div>
        <div className="flex justify-between">
          <div>
            {editName ? (
              <form
                onSubmit={handleSubmit}
                id="customName"
                className="bg-[#2B2B2B]"
              >
                <input
                  className="text-[#f1f1f1] bg-[#2B2B2B]"
                  autoFocus={true}
                  defaultValue={customModelName}
                />
                <button className="text-[#f1f1f1] px-4">Okay</button>
              </form>
            ) : (
              <div
                className={`text-2xl flex ${
                  isModelReady && "hover:text-[#636363]"
                }`}
                {...(isModelReady && {
                  onClick: () => setEditName(true),
                  onMouseOver: () => setIsHoveringName(true),
                  onMouseOut: () => setIsHoveringName(false),
                })}
              >
                {customModelName === "" ? <>Untitled</> : customModelName}
                {isHoveringName && (
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    style={{
                      paddingLeft: "16px",
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="flex justify-between">
            {!isJobCompleted && (
              <button
                className="mr-1 text-center bg-[#2B2B2B] border-[#FF6C71] text-white px-4 py-1 rounded-md hover:bg-[#FF6C71]"
                onClick={() =>
                  cancelJob(
                    activeJob?.job_id ?? "",
                    token,
                    setActiveJob,
                    activeJob,
                    jobs,
                    setJobs,
                  )
                }
              >
                Pause
              </button>
            )}
            {isResumable && (
              <button
                className="mr-1 text-center bg-[#2B2B2B] border-[#FF6C71] px-4 py-1 rounded-md hover:bg-[#FF6C71]"
                onClick={() =>
                  resumeTraining(
                    activeJob?.job_id ?? "",
                    token,
                    setActiveJob,
                    activeJob,
                    jobs,
                    setJobs,
                  )
                }
              >
                Resume
              </button>
            )}
            {showShareIcons && (
              <>
                <Popover className="relative">
                  <Popover.Button className="mr-1 bg-[#2B2B2B] border-[#FF6C71] px-2 py-1 rounded-md hover:bg-[#FF6C71] flex">
                    <img
                      src={shareIcon}
                      alt="share-icon"
                      className="px-2 py-1"
                    />
                    <span className="text-white">Model</span>
                  </Popover.Button>

                  <Popover.Panel className="absolute right-4 z-10 mt-2 max-w-[900px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none p-1">
                    {isPub ? (
                      <div className="p-2 w-[700px]">
                        <p className="shrink-0 text-[#1f1f1f] mr-2 text-[14px]">
                          Public link:
                        </p>
                        <div
                          className="text-xs bg-[#f0f4f8] text-[#1f1f1f] p-2 flex w-full"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              window.location.host +
                                "/share?model_id=" +
                                activeJob.model_name,
                            );
                            setCopiedModelShareLink(true);
                          }}
                        >
                          {window.location.host +
                            "/share?model_id=" +
                            activeJob.model_name}
                          {copiedModelShareLink ? (
                            <img
                              src={checkIcon}
                              alt="check-icon"
                              className="ml-2 w-[16px] "
                            />
                          ) : (
                            <img
                              src={copyIcon}
                              alt="copy-icon"
                              className="ml-2 w-[16px] "
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="w-[400px]">
                        <p className="flex w-full text-[#1f1f1f] ">
                          Sharing this model will create a publicly accessible
                          link. This cannot be undone. Are you sure you want to
                          share?
                        </p>
                        <div className="flex w-full justify-center pt-4">
                          <button
                            className="bg-[#FF6C71] text-white px-4 py-1 rounded-md hover:bg-[#FF6C71] flex"
                            onClick={() =>
                              setPublic(activeJob.model_name, token)
                            }
                          >
                            Yes, confirm
                          </button>
                        </div>
                      </div>
                    )}
                  </Popover.Panel>
                </Popover>

                <Popover className="relative">
                  <Popover.Button className="bg-[#2B2B2B] border-[#FF6C71] px-2 py-1 rounded-md hover:bg-[#FF6C71] flex">
                    <img
                      src={shareIcon}
                      alt="share-icon"
                      className="px-2 py-1"
                    />
                    <span className="text-white">Dataset</span>
                  </Popover.Button>

                  <Popover.Panel className="absolute right-4 z-10 mt-2 max-w-[900px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none p-1">
                    {isPub ? (
                      <div className="p-2 w-[700px]">
                        <p className="shrink-0 text-[#1f1f1f] mr-2 text-[14px]">
                          Public link:
                        </p>
                        <div
                          className="text-xs bg-[#f0f4f8] text-[#1f1f1f] p-2 flex w-full"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              window.location.host +
                                "/share?dataset_id=" +
                                activeJob.dataset_id,
                            );
                            setCopiedDatasetShareLink(true);
                          }}
                        >
                          {window.location.host +
                            "/share?dataset_id=" +
                            activeJob.dataset_id}
                          {copiedDatasetShareLink ? (
                            <img
                              src={checkIcon}
                              alt="check-icon"
                              className="ml-2 w-[16px] "
                            />
                          ) : (
                            <img
                              src={copyIcon}
                              alt="copy-icon"
                              className="ml-2 w-[16px] "
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="w-[400px]">
                        <p className="flex w-full text-[#1f1f1f] ">
                          Sharing this dataset will create a publicly accessible
                          link. This cannot be undone. Are you sure you want to
                          share?
                        </p>
                        <div className="flex w-full justify-center pt-4">
                          <button
                            className="bg-[#FF6C71] text-white px-4 py-1 rounded-md hover:bg-[#FF6C71] flex"
                            onClick={() =>
                              setPublic(activeJob.dataset_id, token)
                            }
                          >
                            Yes, confirm
                          </button>
                        </div>
                      </div>
                    )}
                  </Popover.Panel>
                </Popover>
              </>
            )}
          </div>
        </div>
        <br />
        <div className="flex w-full justify-between">
          <p>Job ID</p>
          <div>
            <p className="m-auto">{statusChip(activeJob?.status ?? "")}</p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p>{activeJob?.job_id}</p>
          <div>
            <p className="text-right">{activeJob?.start_time}</p>
            {!isJobCompleted && <p className="text-right">{timeString}</p>}
          </div>
        </div>
        <br />
        <TrainJobInformation
          activeJob={activeJob}
          copiedModel={copiedModel}
          setCopiedModel={setCopiedModel}
          copiedDataset={copiedDataset}
          setCopiedDataset={setCopiedDataset}
        />
        <br />
      </div>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="border-b border-[#2b2b2b]">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Eval Results
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Playground
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Loss
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Logs
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Arguments
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="p-4 grow">
          <Tab.Panel>
            {jobEvalLoading ? (
              <ul aria-label="Eval Results">
                <li key={"loading"}>
                  <div className="w-full">
                    <Spinner enabled={true}></Spinner>
                  </div>
                </li>
              </ul>
            ) : jobEval && jobEval.length ? (
              <EvalResults jobEval={jobEval} modelId={activeJob.model_name} />
            ) : (
              <div className="text-[14px]">Not available.</div>
            )}
          </Tab.Panel>
          <Tab.Panel className="grow flex flex-col h-full overflow-hidden">
            {activeJob?.model_name ? (
              <InteractivePlayground
                token={token}
                default_model={activeJob.model_name}
                custom_name={activeJob.custom_model_name}
                shouldFetchModel={shouldFetchModel}
                outputLength={256}
                systemPrompt={""}
                maxInferenceTime={15}
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
              />
            ) : (
              <div className="text-[14px]">
                Not available yet. Please check back when your training job is
                completed.
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={data}
                margin={{ top: 20, right: 20, left: 5, bottom: 20 }}
              >
                <XAxis dataKey="step">
                  <Label value="Step" offset={0} position="bottom" />
                </XAxis>
                <YAxis
                  label={{
                    value: "Loss",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                <Line
                  type="monotone"
                  dataKey="train_loss"
                  stroke="#FF6C71"
                  yAxisId={0}
                  connectNulls
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="eval_loss"
                  stroke="#82ca9d"
                  yAxisId={0}
                  connectNulls
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Tab.Panel>
          <Tab.Panel>
            <TrainingLog token={token} job_id={activeJob.job_id} />
          </Tab.Panel>
          <Tab.Panel>
            <TrainArguments token={token} job_id={activeJob.job_id} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
