import * as React from "react";

import { statusChip } from "./statusChip";
import SampleEval from "./SampleEval";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";

interface TrainPageEvalExampleProps {
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

export default function TrainPageEvalExample({
  selectedTab,
  setSelectedTab,
}: TrainPageEvalExampleProps) {
  return (
    <div className={`flex flex-col h-full overflow-y-auto overflow-x-hidden`}>
      <div>
        <div className="flex w-full justify-between">
          <p>Job ID</p>
          <div>
            <p className="m-auto">{statusChip("COMPLETED")}</p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p>OOO</p>
          <div>
            <p className="text-right"></p>
            <p className="text-right"></p>
          </div>
        </div>
        <br />
        <div className="flex w-full justify-between text-[14px]">
          <div>
            <div className="flex w-full justify-start">
              <p>Model ID</p>
            </div>
            <div className="flex w-full justify-start truncate">
              <p className="hover:bg-gray-700">This is a sample job</p>
            </div>
            <br />
            <div className="flex w-full justify-start">
              <p>Dataset ID</p>
            </div>
            <div className="flex w-full justify-start truncate">
              <p className="hover:bg-gray-700">This is a sample job</p>
            </div>
          </div>
        </div>
        <br />
      </div>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="border-b border-[#2b2b2b]">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Eval Results
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Playground
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Loss
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#FF6C71] hover:bg-[#2b2b2b]"
                    : "border-0 font-semibold px-8 py-1 text-[#FF6C71] border-b-2 border-[#2b2b2b] hover:bg-[#2b2b2b]"
                }
              >
                Logs
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="p-4 grow">
          <Tab.Panel>
            <SampleEval />
          </Tab.Panel>
          <Tab.Panel className="grow flex flex-col h-full overflow-hidden">
            <div className="text-[14px]">
              Not available yet. Please check back when your training job is
              completed.
            </div>
          </Tab.Panel>
          <Tab.Panel></Tab.Panel>
          <Tab.Panel>
            <div className="text-[14px]">
              Not available yet. Please check back when your training job is
              completed.
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
