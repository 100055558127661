import React from "react";
import { Header } from "../system/atoms/Header";
import { AlignedSides } from "../system/layouts/AlignedSides";
import logo from "../logos/logo-offwhite.png";
import { NavLink } from "react-router-dom";
import "./NavigationBar.css";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import Statsig from "statsig-js";
import userLogo from "../../assets/icon_user.png";
import { UserInfo } from "../user/UserInfoPage";

const Logo = () => {
  return (
    <div>
      <a href="https://lamini.ai">
        <img src={logo} className="logo h-3 my-4 mx-4" />
      </a>
    </div>
  );
};

interface NavigationBarProps {
  userInfo: UserInfo;
}

function NavigationBar({ userInfo }: NavigationBarProps) {
  return (
    <Header
      content={
        <AlignedSides
          left={
            <div className="flex items-center bg-[#121212]">
              <Logo />
              <div className="hidden md:block">
                <NavLink to="/playground" className="nav_link">
                  Playground
                </NavLink>
              </div>
              <div className="hidden md:block">
                <NavLink to="/tune" className="nav_link">
                  Tune
                </NavLink>
              </div>
              <div className="hidden md:block">
                <a
                  href="https://lamini.ai/examples"
                  target="_blank"
                  className="nav_link"
                >
                  Examples→
                </a>
              </div>
              <div className="hidden md:block">
                <a
                  href="https://lamini-ai.github.io/"
                  target="_blank"
                  className="nav_link"
                >
                  Docs→
                </a>
              </div>
              <div className="hidden md:block">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfrTQLajs4SIioN9xcWe2xxuqCXjR4YBolUE1J8lnuIP96ITA/viewform?usp=sf_link"
                  target="_blank"
                  className="nav_link"
                >
                  Help
                </a>
              </div>
              <div className="hidden md:block">
                <NavLink to="/account" className="nav_link ">
                  Account
                </NavLink>
              </div>
              <div className="hidden md:block">
                <NavLink to="/usage" className="nav_link ">
                  Usage
                </NavLink>
              </div>
              {Statsig.checkGate("admin_console") && (
                <div className="hidden md:block">
                  <NavLink to="/admin" className="nav_link ">
                    Admin
                  </NavLink>
                </div>
              )}
            </div>
          }
          right={
            <div className="flex">
              <Menu>
                {({ open }) => (
                  <>
                    <MenuButton className="px-4 py-3 w-full justify-between text-sm font-semibold text-white md:hidden">
                      {open ? (
                        <XMarkIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      )}
                    </MenuButton>

                    <MenuItems className="md:hidden absolute h-[99vh] top-[38px] right-0 p-[6px] pt-10 z-10 mt-2 w-[100vw] max-w-[900px] origin-top-right rounded-md bg-[#121212] shadow-lg focus:outline-none flex flex-col">
                      <MenuItem>
                        <NavLink
                          to="/playground"
                          className={({ isActive }) =>
                            isActive
                              ? "h-[10vh] p-6 hover:text-[#FF6C71] text-center text-[#FF6C71]"
                              : "h-[10vh] p-6 hover:text-[#FF6C71] text-center"
                          }
                        >
                          Playground
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          to="/tune"
                          className={({ isActive }) =>
                            isActive
                              ? "h-[10vh] p-6 hover:text-[#FF6C71] text-center text-[#FF6C71]"
                              : "h-[10vh] p-6 hover:text-[#FF6C71] text-center"
                          }
                        >
                          Tune
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="https://lamini.ai/examples"
                          target="_blank"
                          className="h-[10vh] p-6 hover:text-[#FF6C71] text-center"
                        >
                          Examples→
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="https://lamini-ai.github.io/"
                          target="_blank"
                          className="h-[10vh] p-6 hover:text-[#FF6C71] text-center"
                        >
                          Docs→
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSfrTQLajs4SIioN9xcWe2xxuqCXjR4YBolUE1J8lnuIP96ITA/viewform?usp=sf_link"
                          target="_blank"
                          className="h-[10vh] p-6 hover:text-[#FF6C71] text-center"
                        >
                          Help
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          to="/account"
                          className={({ isActive }) =>
                            isActive
                              ? "h-[10vh] p-6 hover:text-[#FF6C71] text-center text-[#FF6C71]"
                              : "h-[10vh] p-6 hover:text-[#FF6C71] text-center"
                          }
                        >
                          Account
                        </NavLink>
                      </MenuItem>
                    </MenuItems>
                  </>
                )}
              </Menu>
              <Menu>
                <MenuButton className="hidden md:block">
                  <div className="w-12 h-12 p-2 rounded-full overflow-hidden dark:border-white border-gray-900">
                    <img
                      src={userLogo}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </MenuButton>

                <MenuItems
                  anchor="bottom"
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-[#1D1D1D] ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem>
                    <div className="block p-4 border-b-2 border-[#393939] ">
                      <div className="text-xs">{userInfo.email}</div>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <a
                      className="block p-2  m-2 cursor-pointer rounded hover:text-[#FF6C71]"
                      href={
                        (process.env.REACT_APP_API_URL || "") +
                        "/v1/auth/logout"
                      }
                    >
                      Logout
                    </a>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          }
        />
      }
    ></Header>
  );
}

export default NavigationBar;
